<template>
    <div class="wrapper">
        <div class="cols">
            <div class="col" v-for="(item,index) in imgList" :key="index" @click="selectedItem(item,index)" 
                :class="{colclass:colindex == index}">
                <div class="container">
                    <div class="front" :style="'background-image:url('+item.logoUrl+')'">
                        <div class="inner">
                            <span style="font-size: 2vh;"> {{ item.title }}</span>
                        </div>
                    </div>
                    <div class="back">
                        <div class="inner">
                            <!-- <p>{{ item.summary }}</p> -->
                            <span style="font-size: 2vh;margin: 3vh;"> {{ item.title }}</span>
                            <div @click="goDetail" style="border-radius: 5px;background-color: rgb(0, 140, 255);padding: 1vh;z-index: 99;font-size: 1.5vh;">进入详情</div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="imgList.length < 5" style="margin: 10vh auto;color: rgb(182, 182, 182);">没有更多内容了哦~~</div>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            imgList: [],
            pageSize: 9,
            page: 1,
            totalPage: 0,
            active: 0,
            current:'',
            colindex:0
        }
    },
    mounted () {
        this.getImgList()
    },
    methods: {
        // 去详情
        goDetail () {
            this.$router.push({
                path: '/CulturalDetail',
                query: {
                    id: this.current.culturalId
                }
            });
        },
        // 获取列表
        getImgList () {
            let t = this
            var axios = require('axios');
            var config = {
                method: 'post',
                url: this.$Schttp + '/vtp/app/cultural/page',
                headers: {},
                data: {
                    pageSize: this.pageSize,
                    pageNum: this.page,
                }
            };
            axios(config).then( (res)=> {
                this.imgList = res.data.rows
                this.totalPage = Math.ceil(res.data.total / this.pageSize) || 1
                // t.$parent.current = t.imgList[0]
                this.selectedItemTwo()
            })
        },
        selectedItem (data, idx) {
            this.colindex = idx
            // 获取详情
            let t = this;
            var axios = require('axios');
            var config = {
                method: 'get',
                url: t.$Schttp + '/vtp/app/cultural/detail/' + data.culturalId,
            };
            axios(config).then( (res)=> {
                this.$parent.current = res.data.data
                this.current = res.data.data
                // 调用父组件
                this.$parent.Father()
            }).catch(function (error) {});
            this.active = idx
        },
        selectedItemTwo(){
            var axios = require('axios');
            var config = {
                method: 'get',
                url: this.$Schttp + '/vtp/app/cultural/detail/' + this.imgList[0].culturalId,
            };
            axios(config).then( (res)=> {
                this.$parent.current = res.data.data
                this.current = res.data.data
                // 调用父组件
                this.$parent.Father()
            }).catch(function (error) {});
            this.active = 0
        },
        // 下一页
        nextPage () {
            console.log('下一页', this.totalPage, this.page)
            if (this.totalPage > this.page) {
                this.page++
                this.getImgList()
            } else {
                this.page = 1
                this.getImgList()
            }
        }
    }
}
</script>
<style lang="less" scoped>
.wrapper {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    // max-width: 40rem;
    min-height: 75vh;
    .nextPage {
        text-align: right;
    }
    .cols {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        .col {
            width: calc(33% - 2rem);
            margin: 0.5rem;
            cursor: pointer;
            
            .container {
                -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
                -webkit-perspective: 1000px;
                perspective: 1000px;
                
                .front,
                .back {
                    background-size: cover;
                    background-position: center;
                    -webkit-transition: -webkit-transform 0.7s
                        cubic-bezier(0.4, 0.2, 0.2, 1);
                    transition: -webkit-transform 0.7s
                        cubic-bezier(0.4, 0.2, 0.2, 1);
                    -o-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
                    transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
                    transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1),
                        -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
                    -webkit-backface-visibility: hidden;
                    backface-visibility: hidden;
                    text-align: center;
                    min-height: 23vh;
                    height: auto;
                    border-radius: 10px;
                    color: #fff;
                    font-size: 1.5rem;
                    
                }

                .front {
                    -webkit-transform: rotateY(0deg);
                    transform: rotateY(0deg);
                    -webkit-transform-style: preserve-3d;
                    transform-style: preserve-3d;
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    background-color: #000;
                    
                    .inner p {
                        font-size: 2rem;
                        margin-bottom: 2rem;
                        position: relative;
                        span {
                            color: rgba(255, 255, 255, 0.7);
                            font-family: 'Montserrat';
                            font-weight: 300;
                        }
                    }
                    .inner p:after {
                        content: '';
                        width: 4rem;
                        height: 2px;
                        position: absolute;
                        background: #c6d4df;
                        display: block;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        bottom: -0.75rem;
                    }
                }

                .front:after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    width: 100%;
                    height: 100%;
                    content: '';
                    display: block;
                    opacity: 0.6;
                    background-color: #000;
                    -webkit-backface-visibility: hidden;
                    backface-visibility: hidden;
                    border-radius: 10px;
                    
                }

                .back {
                    -webkit-transform: rotateY(180deg);
                    transform: rotateY(180deg);
                    -webkit-transform-style: preserve-3d;
                    transform-style: preserve-3d;
                    background: #cedce7;
                    background: -webkit-linear-gradient(
                        45deg,
                        #cedce7 0%,
                        #596a72 100%
                    );
                    background: -o-linear-gradient(
                        45deg,
                        #cedce7 0%,
                        #596a72 100%
                    );
                    background: linear-gradient(
                        45deg,
                        #cedce7 0%,
                        #596a72 100%
                    );
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    
                    .inner p {
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        -webkit-line-clamp: 6; //行数
                    }
                }

                .inner {
                    -webkit-transform: translateY(-50%) translateZ(60px)
                        scale(0.94);
                    transform: translateY(-50%) translateZ(60px) scale(0.94);
                    top: 50%;
                    position: absolute;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    padding: 0.5rem;
                    font-size: 0.7em;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    outline: 1px solid transparent;
                    -webkit-perspective: inherit;
                    perspective: inherit;
                    z-index: 2;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                }
            }

            .container:hover .front,
            .container:hover .back {
                -webkit-transition: -webkit-transform 0.7s
                    cubic-bezier(0.4, 0.2, 0.2, 1);
                transition: -webkit-transform 0.7s
                    cubic-bezier(0.4, 0.2, 0.2, 1);
                -o-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
                transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
                transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1),
                    -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
            }

            .container:hover .back {
                -webkit-transform: rotateY(0deg);
                transform: rotateY(0deg);
                -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
            }

            .container:hover .front {
                -webkit-transform: rotateY(-180deg);
                transform: rotateY(-180deg);
                -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
            }
        }
        .colclass{
            .container {
                -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
                -webkit-perspective: 1000px;
                perspective: 1000px;
                
                .front,
                .back {
                    background-size: cover;
                    background-position: center;
                    -webkit-transition: -webkit-transform 0.7s
                        cubic-bezier(0.4, 0.2, 0.2, 1);
                    transition: -webkit-transform 0.7s
                        cubic-bezier(0.4, 0.2, 0.2, 1);
                    -o-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
                    transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
                    transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1),
                        -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
                    -webkit-backface-visibility: hidden;
                    backface-visibility: hidden;
                    text-align: center;
                    min-height: 23vh;
                    height: auto;
                    border-radius: 10px;
                    color: #fff;
                    font-size: 1.5rem;
                    box-shadow: 0 0 0.3125rem #fff,
                0 0 0.9375rem #e70,
                0 0 1.875rem #e70;
                }

                .front {
                    -webkit-transform: rotateY(0deg);
                    transform: rotateY(0deg);
                    -webkit-transform-style: preserve-3d;
                    transform-style: preserve-3d;
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    background-color: #000;
                    box-shadow: 0 0 0.3125rem #fff,
                    0 0 0.9375rem #e70,
                    0 0 1.875rem #e70;
                    .inner p {
                        font-size: 2rem;
                        margin-bottom: 2rem;
                        position: relative;
                        span {
                            color: rgba(255, 255, 255, 0.7);
                            font-family: 'Montserrat';
                            font-weight: 300;
                        }
                    }
                    .inner p:after {
                        content: '';
                        width: 4rem;
                        height: 2px;
                        position: absolute;
                        background: #c6d4df;
                        display: block;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        bottom: -0.75rem;
                        
                    }
                }

                .front:after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    width: 100%;
                    height: 100%;
                    content: '';
                    display: block;
                    opacity: 0.6;
                    background-color: #000;
                    -webkit-backface-visibility: hidden;
                    backface-visibility: hidden;
                    border-radius: 10px;
                    box-shadow: 0 0 0.3125rem #fff,
                    0 0 0.9375rem #e70,
                    0 0 1.875rem #e70;
                }

                .back {
                    -webkit-transform: rotateY(180deg);
                    transform: rotateY(180deg);
                    -webkit-transform-style: preserve-3d;
                    transform-style: preserve-3d;
                    background: #cedce7;
                    background: -webkit-linear-gradient(
                        45deg,
                        #cedce7 0%,
                        #596a72 100%
                    );
                    background: -o-linear-gradient(
                        45deg,
                        #cedce7 0%,
                        #596a72 100%
                    );
                    background: linear-gradient(
                        45deg,
                        #cedce7 0%,
                        #596a72 100%
                    );
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    .inner p {
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        -webkit-line-clamp: 6; //行数
                    }
                }

                .inner {
                    -webkit-transform: translateY(-50%) translateZ(60px)
                        scale(0.94);
                    transform: translateY(-50%) translateZ(60px) scale(0.94);
                    top: 50%;
                    position: absolute;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    padding: 0.5rem;
                    font-size: 0.7em;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    outline: 1px solid transparent;
                    -webkit-perspective: inherit;
                    perspective: inherit;
                    z-index: 2;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                }
            }

            .container:hover .front,
            .container:hover .back {
                -webkit-transition: -webkit-transform 0.7s
                    cubic-bezier(0.4, 0.2, 0.2, 1);
                transition: -webkit-transform 0.7s
                    cubic-bezier(0.4, 0.2, 0.2, 1);
                -o-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
                transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
                transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1),
                    -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
            }

            .container:hover .back {
                -webkit-transform: rotateY(0deg);
                transform: rotateY(0deg);
                -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
            }

            .container:hover .front {
                -webkit-transform: rotateY(-180deg);
                transform: rotateY(-180deg);
                -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
            }
        }
    }
}

@media screen and (max-width: 64rem) {
    .col {
        width: calc(33.333333% - 2rem);
    }
}

@media screen and (max-width: 48rem) {
    .col {
        width: calc(50% - 2rem);
    }
}

@media screen and (max-width: 32rem) {
    .col {
        width: 100%;
        margin: 0 0 2rem 0;
    }
}
</style>